import React from "react";
import Layout from "../components/Layout";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(../images/capacitacion_inicial.jpeg)`}}>
                        <h1>Capacitación Inicial</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            En muchas compañías hay quienes consideran la capacitación como un gasto innecesario; sin
                            embargo, se trata de una de las grandes inversiones que una empresa puede hacer para
                            potenciar el desarrollo de su personal y lograr los objetivos de la organización.
                        </p>
                        <p className="services_section_1__paragraph">
                            Cuanto mayor sea el grado de formación y preparación del personal, más alto será su nivel de
                            productividad, tanto cualitativa como cuantitativamente. En este sentido, los programas de
                            formación profesional o consultoría empresarial constituyen una de las inversiones más
                            rentables, pues si una organización no avanza de forma pareja sufrirá consecuencias como
                            estancamiento, retroceso o imposibilidad para competir en el mercado.
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000000'}}>La capacitación continua de personal permite a los empleados
                                planear, mejorar y realizar de manera más eficiente sus actividades, en colaboración con
                                los demás integrantes de la organización; por lo tanto, es relevante constituir un
                                equipo de trabajo de alto rendimiento y realizar una labor profesional con los mejores
                                estándares de calidad.</p>
                            <p style={{color: '#000000'}}>En general, los principales beneficios que una organización
                                obtiene de la capacitación son los siguientes:</p>
                            <div>
                                <ul style={{color: '#000000'}}>
                                    <li>Aumentar la productividad y la calidad del trabajo.</li>
                                    <li>Incrementar la rentabilidad.</li>
                                    <li>Disminuir la rotación de personal.</li>
                                    <li>Mejorar los estándares de reclutamiento y selección de personal.</li>
                                    <li>Levantar la moral de los trabajadores.</li>
                                    <li>Ayudar a resolver problemas concretos en el día a día.</li>
                                    <li>Disminuir la necesidad de supervisión.</li>
                                    <li>Prevenir accidentes de trabajo.</li>
                                    <li>Mejorar la estabilidad de la organización y su flexibilidad.</li>
                                    <li>Lograr que el personal se sienta identificado con la empresa.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
